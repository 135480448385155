export const CHORDS = "CHORDS";
export const HEATMAP = "HEATMAP";
export const HIGHLIGHT = "HIGHLIGHT";
export const HISTOGRAM = "HISTOGRAM";
export const LINE = "LINE";
export const SCATTER = "SCATTER";
export const STACK = "STACK";
export const TEXT = "TEXT";

export const TRACK_TYPES = [
  CHORDS,
  HEATMAP,
  HIGHLIGHT,
  HISTOGRAM,
  LINE,
  SCATTER,
  STACK,
  TEXT
];
